<template>
  <div>
    <div v-if="type == 'add'">
      <a-form-model
        :model="formInline"
        @submit="handleSubmit"
        @submit.native.prevent
      >
        <a-form-model-item label="品牌名称">
          <a-input v-model="formInline.mbName" placeholder=""> </a-input>
        </a-form-model-item>
        <a-form-model-item label="品牌描述">
          <a-input v-model="formInline.mbDesc" placeholder=""> </a-input>
        </a-form-model-item>
        <a-form-model-item label="所属公司">
          <a-select v-model="formInline.mbBcId" class="search-expand-value">
            <a-select-option
              v-for="opt in filters.GetCompanies"
              :key="opt.bc_id"
              :value="opt.bc_id"
              >{{ opt.bc_name }}</a-select-option
            >
          </a-select>
        </a-form-model-item>
        <a-form-model-item>
          <a-button :style="{ marginRight: '8px' }" @click="onClose">
            取消
          </a-button>
          <a-button type="primary" html-type="submit"> 提交 </a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
    <div v-if="type == 'edit'">
      <a-form-model
        :model="formInlineEdit"
        @submit="handleSubmit"
        @submit.native.prevent
      >
        <a-form-model-item label="所属公司">
          <a-select
            v-model="formInlineEdit.mbBcId"
            class="search-expand-value"
            disabled
          >
            <a-select-option
              v-for="opt in filters.GetCompanies"
              :key="opt.bc_id"
              :value="opt.bc_id"
              >{{ opt.bc_name }}</a-select-option
            >
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="品牌名称">
          <a-input v-model="formInlineEdit.mbName" placeholder=""> </a-input>
        </a-form-model-item>
        <a-form-model-item label="品牌描述">
          <a-input v-model="formInlineEdit.mbDesc" placeholder=""> </a-input>
        </a-form-model-item>

        <!-- <a-form-model-item label="描述">
          <a-input v-model="formInlineEdit.mbSupId" placeholder=""> </a-input>
        </a-form-model-item> -->
        <a-form-model-item>
          <a-button :style="{ marginRight: '8px' }" @click="onClose">
            取消
          </a-button>
          <a-button type="primary" html-type="submit"> 提交 </a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
  </div>
</template>

<script>
import { GetCompanies } from "@/api/device";
import { AddMaterialBrand, UpdateMaterialBrand } from "@/api/apiJF/template";
export default {
  name: "MaterialBrandEdit",
  components: {},
  props: {
    record: {
      type: Object,
    },
    type: {
      type: String,
    },
  },
  data() {
    return {
      formInline: {
        mbName: "",
        mbDesc: "",
        mbBcId: "",
      },
      formInlineEdit: {
        mbId: "", //类别id
        mbBcId: "", //分公司id
        mbName: "", //名称
        // mbSupId: "", //上级类别id
        createTime: "", //创建时间
        mbDesc: "", //品牌描述
        mbOnly: "",
      },
      filters: { GetCompanies: [] },
    };
  },
  created() {
    console.log(this.type, this.record);
    if (this.type == "edit") {
      // this.formInlineEdit.mbName = this.record.mbName;
      // this.formInlineEdit.mbBcId = this.record.mbBcId;
      // this.formInlineEdit.mbSupId = this.record.mbSupId;
      // this.formInlineEdit.mbDesc = this.record.mbDesc;
      for (let key in this.formInlineEdit) {
        for (let el in this.record) {
          if (key == el) {
            this.formInlineEdit[key] = this.record[el];
          }
        }
      }
    } else {
      // this.formInline.mbSupId = this.record ? this.record.mbId : "0";
    }
    // 获取分公司信息
    GetCompanies().then((res) => {
      // console.log("GetCompanies", res.data);
      this.filters.GetCompanies = res.data;
    });
  },
  mounted() {},
  watch: {},
  methods: {
    handleSubmit(e) {
      if (this.type == "add") {
        // console.log("提交");
        let addVerify = Object.keys(this.formInline);
        // console.log("addVerify", addVerify);
        const orderParam = this.$order(this.formInline, addVerify);
        const params = {
          ...orderParam,
          verify: this.$verify(orderParam, addVerify),
        };
        AddMaterialBrand(params).then((res) => {
          // console.log(res);
          if (res.code == "200") {
            this.$message.success("添加成功");
            this.onClose();
          } else {
            this.$message.error(res.msg);
          }
        });
      } else if (this.type == "edit") {
        this.formInlineEdit.mbId = this.record.mbId;
        this.formInlineEdit.createTime = this.record.createTime;
        let params = this.formInlineEdit;
        UpdateMaterialBrand(params).then((res) => {
          // console.log(res);
          if (res.code == "200") {
            this.$message.success("修改成功");
            this.onClose();
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    onClose() {
      this.$emit("onClose");
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.ant-row {
  display: flex;
  width: 90%;
}
/deep/ .ant-form-item-control-wrapper {
  width: 80%;
}
/deep/ .ant-form-item-label {
  width: 200px;
}
/deep/ .ant-form {
  text-align: center;
}
</style>